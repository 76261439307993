<template xmlns="http://www.w3.org/1999/html">
  <!-- 活动页面 -->
  <div class="m-page">
    <div class="zt-banner">
      <div class="container">
        <div
          class=""
          style="margin: 0 auto; text-align: center; padding-top: 30px"
        >
          <span
            v-if="!this.user"
            style="
              color: #fa6400;
              font-size: 16px;
              cursor: pointer;
              background-color: #fff;
              padding: 2px 12px;
              border-radius: 40px;
              margin-left: 200px;
              box-shadow: 0 4px 4px #fa6400;
            "
            @click="login"
            >登录</span
          >

          <img
            style="
              border-radius: 50%;
              vertical-align: middle;
              cursor: pointer;
              width: 32px;
              height: 32px;
              margin-left: 260px;
              border: #e53400 0.5px solid;
              box-shadow: 1px 1px 1px #e53400;
            "
            v-else
            :src="
              this.user
                ? this.user.avatar
                : 'https://attach.cspiration.com/userContent/4376/d0574fdefd7642de820921a656b07049.png'
            "
          />
        </div>
        <div class="zt-grid">
          <div class="zt-lg flex flex-ac flex-hc">
            <img src="@/assets/activity_images/m/tt1.png" />
            <img src="@/assets/activity_images/m/tt2.png" />
          </div>
          <div class="zt-sm flex flex-ac flex-hc">
            <img src="@/assets/activity_images/m/sm-bt.png" />
            <img src="@/assets/activity_images/m/day6.png" />
          </div>
          <div class="zt-text">
            注意：以上课程仅指 CSON官方出品的 <br />「{{
              this.giveCourseName
            }}」
          </div>
          <div class="zt-link flex flex-ac flex-hc" style="display: block">
            <a href="javascript:;" @click="getInvitationLink" class="btn"
              >立即参与</a
            >
            <a
              href="javascript:;"
              class="zt-text"
              style="display: block; width: 100px; margin: 0 auto"
              @click="getInvitationList"
              >邀请记录</a
            >
          </div>
          <div class="gift-box"></div>
        </div>
      </div>
    </div>
    <div class="zt-wrapper">
      <div class="container">
        <div class="zt-panel">
          <div class="abs-text"></div>
          <div class="inner">
            <div class="zt-cell zt-cell-1">
              <div class="zt-head flex flex-ac flex-zBetween">
                <div class="zt-tit">邀请规则</div>
                <img src="../../assets/activity_images/m/invite@2x.png" />
              </div>
              <div class="zt-tip">
                活动期间，每邀请一位好友使用您的邀请码注册成功、成功购课，您和TA都将获得课程免费学习时长：
              </div>
              <ul class="zt-list">
                <li v-if="buyCombo">
                  <img
                    src="../../assets/activity_images/m/flay.png"
                  />邀请好友注册成功，获得「{{ this.giveCourseName }}」<span
                    class="lg-red"
                    >{{ buyCombo.complimentaryDay }}天</span
                  >
                  延期；
                </li>
                <li v-if="invitationRegister">
                  <img
                    src="../../assets/activity_images/m/flay.png"
                  />邀请好友购课成功，获得「{{ this.giveCourseName }}」<span
                    class="lg-red"
                    >{{ invitationRegister.complimentaryDay }}天</span
                  >
                  延期；
                </li>
                <li>
                  <img src="../../assets/activity_images/m/flay.png" />邀请者
                  <span class="lg-red">最多</span> 获得
                  <span class="lg-red">{{ tallest }}天</span> 奖励。
                </li>
              </ul>
              <!--              <div class="zt-end">*被邀请者成功注册后，邀请者需主动联系客服，发送被邀请者<br>绑定用户名或邮箱，审核通过即发放奖励。</div>-->
            </div>
            <div class="zt-cell zt-cell-2">
              <div class="zt-head flex flex-ac flex-zBetween">
                <div class="zt-tit">
                  奖励发放
                  <img src="../../assets/activity_images/m/sm-gift.png" />
                </div>
                <img src="../../assets/activity_images/m/reward@2x.png" />
              </div>
              <div class="zt-end">
                奖励会叠加在您的课程中，您可以通过“我的课程”查看您的课程奖励
              </div>
            </div>
            <div class="zt-cell zt-cell-3">
              <div class="zt-head flex flex-ac flex-zBetween">
                <div class="zt-tit">特别说明</div>
                <img src="../../assets/activity_images/m/Explanation@2x.png" />
              </div>
              <ul class="zt-list">
                <li>
                  <img
                    src="../../assets/activity_images/m/sanjiao.png"
                  />以上奖励规则仅适用于
                  <span class="bg-sm"
                    >{{ this.active.startTime }}-{{
                      this.active.terminationTime
                    }}期间</span
                  >
                  邀请注册并下单购课的好友;
                </li>
                <li>
                  <img src="../../assets/activity_images/m/sanjiao.png" /><span
                    class="bg-sm"
                    >同一邮箱、同一微信ID</span
                  >
                  都将被视为同一个用户，不再重复获得奖励。
                </li>
              </ul>
              <div class="zt-end">
                *如果您没有收到奖励，请在微信中与我们联系核实。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <div class="dialog-main">
        <div class="d-title">{{ this.active.activeName }}</div>
        <div class="d-text" style="margin-bottom: 20px">
          最高可享 <span class="lg">{{ this.tallest }}天</span> 免费学
        </div>
        <div class="d-flex-code flex flex-ac">
          <div class="txt">专属邀请链接</div>
          <input
            v-model="userInvitationLink"
            readonly="readonly"
            style="margin-right: 2px"
          />
          <div class="btn" @click="copyUrl">复制</div>
        </div>
        <div class="d-wx">
          <img src="../../assets/activity_images/m/wx.png" />
        </div>
        <div class="d-sm">扫码获取活动详细流程</div>
      </div>
    </el-dialog>
    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible2" :close-on-click-modal="false">
      <div class="dialog-main">
        <div class="d-title" style="margin-bottom: 25px">新用户福利</div>
        <ul class="zt-list">
          <li>
            <p style="text-align: center">
              <img src="../../assets/activity_images/m/sanjiao.png" />{{
                this.giveCourseName
              }}
            </p>
            <p style="text-align: center">
              <span class="bgx-sm"
                >免费学<span class="lg">{{
                  this.register.complimentaryDay
                }}</span
                >天</span
              >
            </p>
          </li>
          <li>
            <img
              src="../../assets/activity_images/m/sanjiao.png"
            />活动时间：<span class="bg-sm"
              >{{ this.active.startTime }}-{{
                this.active.terminationTime
              }}</span
            >
          </li>
        </ul>
        <a href="javascript:;" class="d-button"
          >请前往PC端注册领取<br />
          cspiration.com
        </a>
      </div>
    </el-dialog>

    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible4">
      <div class="dialog-main">
        <ul class="zt-list" style="text-align: center">
          <li>
            请前往PC端注册领取福利<br />
            cspiration.com
          </li>
        </ul>
      </div>
    </el-dialog>

    <el-dialog custom-class="custom-dialog" :visible.sync="dialogFormVisible3">
      <div class="dialog-main">
        <div class="d-title" style="margin-bottom: 30px">邀请记录</div>
        <div class="table-in">
          <table class="el-table">
            <thead>
              <tr>
                <td colspan="2">用户名</td>
                <td>状态</td>
                <td>奖励天数</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userInvitationList" :key="index">
                <td style="padding: 0 0; margin: 0 0">
                  <img
                    style="width: 28px; border-radius: 40px"
                    :src="item.avatar"
                  />
                </td>
                <td style="text-align: left">{{ item.username }}</td>
                <td>
                  {{
                    item.activeChannelType.value == 0
                      ? "新用户注册"
                      : item.activeChannelType.value == 1
                      ? "激活成功"
                      : "购课成功"
                  }}
                </td>
                <td>奖励{{ item.complimentaryDay }}天</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  invitationLink,
  invitationList,
  wechatLogin,
  getAcitve,
} from "../../service/index.js";

export default {
  components: {},
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      userInvitationList: [],
      userInvitationLink: "",
      user: null,
      active: {},
      tallest: 0,
      buyCombo: {},
      invitationRegister: {},
      register: {},
      giveCourseName: "",
    };
  },
  async created() {
    var token = this.$route.query.token;
    if (token) {
      window.localStorage.setItem("CSON_PAGE_TOKEN", token);
      await this.$store.dispatch("queryUserInfo");
      console.log(this.$store.state.userInfo);
      this.user = this.$store.state.userInfo;
    }
    var err = this.$route.query.err;
    if (err) {
      this.dialogFormVisible4 = true;
    }
    this.getInvitationActive();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getInvitationActive() {
      //邀请活动固定写 0
      getAcitve(0).then((res) => {
        if (res.success) {
          this.active = res.result;
          res.result.activeDetails.forEach((item) => {
            this.tallest += item.complimentaryDay * item.limitNum;
            this.giveCourseName = item.giveCourseName;
            switch (item.activeChannelType.value) {
              case 0:
                this.register = item;
                break;
              case 1:
                this.invitationRegister = item;
                break;
              case 2:
                this.buyCombo = item;
                break;
              default:
                break;
            }
          });
          this.tallest += 1;
        }
      });
    },
    async login() {
      wechatLogin().then((res) => {
        if (res.success) {
          window.location.href = res.result.url;
        }
      });
    },
    getUser() {},
    getInvitationList() {
      console.log(1111);
      this.dialogFormVisible3 = true;
      if (this.user) {
        invitationList(1).then((res) => {
          if (res.success) {
            this.userInvitationList = res.result;
          }
        });
      }
    },
    getInvitationLink() {
      if (this.user) {
        this.dialogFormVisible = true;
        invitationLink().then((res) => {
          if (res.success) {
            this.userInvitationLink = res.result;
          }
        });
      } else {
        this.dialogFormVisible2 = true;
      }
    },
    copyUrl() {
      var domUrl = document.createElement("input");
      domUrl.value = this.userInvitationLink;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message.success("复制成功");
    },
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("class", "mm-activity");
  },
};
</script>

<style scoped="scoped">
.flex {
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}

/* 主轴居中 */
.flex-hc {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 主轴两端对齐 */
.flex-zBetween {
  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* 主轴end对齐 */
.flex-zEnd {
  -webkit-box-pack: end;
  -moz-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/* 主轴start对齐 */
.flex-zStart {
  -webkit-box-pack: start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

/* 侧轴居中 */
.flex-ac {
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

/* 侧轴start对齐 */
.flex-cStart {
  -webkit-box-align: start;
  -moz-align-items: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

/* 侧轴底部对齐 */
.flex-cEnd {
  -webkit-box-align: end;
  -moz-align-items: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

/* 侧轴文本基线对齐 */
.flex-cBaseline {
  -webkit-box-align: baseline;
  -moz-align-items: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

/* 侧轴上下对齐并铺满 */
.flex-cStretch {
  -webkit-box-align: stretch;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

/* 主轴从上到下 */
.flex-zTopBottom {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

/* 主轴从下到上 */
.flex-zBottomTop {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

/* 主轴从左到右 */
.flex-zLeftRight {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

/* 主轴从右到左 */
.flex-zRightLeft {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* 不允许子元素缩小 */
.flex-shrink {
  -webkit-box-flex: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

/*各个行中间对齐*/
.flex-center {
  -webkit-align-content: center;
  align-content: center;
}

/*各个行中间对齐*/
.flex-start {
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

/*各个行中间对齐*/
.flex-end {
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

/*各个行平均分布*/
.flex-between {
  -webkit-align-content: space-between;
  align-content: space-between;
}

/*各个行两端保留子元素与子元素之间间距大小的一半*/
.flex-container {
  -webkit-align-content: space-around;
  align-content: space-around;
}

/*父元素-横向换行 */
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*父元素-不允许横向换行 */
.flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

/*充满父元素*/
.flex_bd {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

/* 显示两行文字 */
.line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/* 文字省略号 */
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* =---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.zt-banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  height: 397px;
  background-size: cover;
  background-image: url(../../assets/activity_images/m/banner.png);
}

.zt-banner .gift-box {
  position: absolute;
  width: 100px;
  height: 100px;
  right: 0;
  bottom: 0;
}

.zt-banner .container {
  height: 100%;
  position: relative;
}

.zt-wrapper {
  position: relative;
  z-index: 1999;
  padding: 0 20px;
  background: url(../../assets/activity_images/m/bg.png) center top no-repeat;
  background-size: cover;
  padding-bottom: 130px;
}

.zt-wrapper .container {
  position: relative;
  top: -30px;
}

.zt-wrapper .zt-panel .abs-text {
  display: none;
}

.zt-head {
  margin-bottom: 25px;
}

.zt-head .zt-tit {
  position: relative;
  width: 92px;
  height: 24px;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  background: url(../../assets/activity_images/m/tit.png) no-repeat;
  background-size: 100% 100%;
}

.zt-head > img {
  height: 17.5px;
}

.zt-head .zt-tit img {
  position: absolute;
  right: -20px;
  top: -10px;
}

.zt-tip {
  background: #ffe6e5;
  border-radius: 2px;
  padding: 6px 10px;
  font-size: 12px;
  margin-bottom: 20px;
}

.zt-list {
  margin-bottom: 20px;
}

.zt-end {
  padding: 6px 10px;
  font-size: 12px;
  background-color: #ffe6e5;
  color: #ff5247;
  text-align: center;
}

.zt-cell {
  padding: 40px 22px 50px 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-bottom: 20px;
}

.zt-cell-1 {
  background-image: url(../../assets/activity_images/m/card1.png);
}

.zt-cell-2 {
  background-image: url(../../assets/activity_images/m/card2.png);
}

.zt-cell-3 {
  background-image: url(../../assets/activity_images/m/card3.png);
}

.lg-red {
  color: #ff5247;
}

.zt-list li {
  margin-bottom: 20px;
  font-size: 12px;
}

.zt-list li:last-child {
  margin-bottom: 0;
}

.zt-list li img {
  margin-right: 7px;
  width: 14px;
}

.zt-list li .bg-sm {
  display: inline-block;
  color: #ff5247;
  line-height: 24px;
  padding: 0 10px;
  background: #ffe6e5;
}

.zt-list li .bgx-sm {
  display: inline-block;
  color: #333;
  line-height: 24px;
  padding: 0 10px;
  background: #ffe6e5;
}

.bgx-sm .lg {
  color: #ff5247;
  font-size: 18px;
}

.zt-grid {
  text-align: center;
  padding-top: 30px;
}

.zt-lg {
  margin-bottom: 15px;
}

.zt-lg img {
  height: 56px;
}

.zt-sm {
  margin-bottom: 15px;
}

.zt-sm img:first-child {
  height: 24px;
}

.zt-sm img:last-child {
  height: 31.5px;
}

.zt-text {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 27px;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 30px;
}

.zt-link .btn {
  display: block;
  width: 215px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  color: #ff2b39;
  font-size: 16px;
  background: url(../../assets/activity_images/m/button.png) no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.box-popover {
  font-size: 12px;
  text-align: center;
}

.box-popover .inner {
  padding: 15px 0;
}

.box-popover .txt1 {
  color: #fa6400;
  margin-top: 5px;
}

.box-popover .txt2 {
  color: rgba(0, 0, 0, 0.5);
}

::v-deep .custom-dialog {
  width: 85%;
  text-align: center;
  border-radius: 10px;
  background: url(../../assets/activity_images/m/pop.png) no-repeat bottom
    center;
  background-size: cover;
}

::v-deep .custom-dialog .el-dialog__header {
  height: 0;
  padding: 0;
}

.dialog-main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dialog-main .d-title {
  height: 23px;
  padding-left: 15px;
  padding-right: 10px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 10px;
  background: url(../../assets/activity_images/m/ttlg.png) no-repeat;
  background-size: 100% 100%;
}

.dialog-main .d-text {
  font-size: 13px;
  color: #ff5247;
  margin-bottom: 10px;
  background: url(../../assets/activity_images/m/line.png) center bottom
    no-repeat;
  background-size: 100% 15px;
  padding: 0 15px;
}

.dialog-main .d-text .lg {
  font-size: 18px;
}

.dialog-main .d-wx img {
  width: 100px;
}

.dialog-main .d-sm {
  font-size: 12px;
  color: #ff5247;
}

.dialog-main .zt-list {
  width: 95%;
  text-align: left;
}

.dialog-main .zt-list li {
  font-size: 13px;
  white-space: nowrap;
}

.dialog-main .d-button {
  width: 180px;
  height: 40px;
  background-color: #ff5247;
  border-radius: 40px;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
  padding-top: 5px;
}

.m-page + .house-footer {
  display: none;
}

body {
  width: 100%;
  min-width: 100%;
}

.container {
  width: 100%;
}

::v-deep .el-dialog__headerbtn {
  color: #ffffff;
  border: 1px solid #fffffe;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  right: auto;
  left: 50%;
  top: auto;
  bottom: -40px;
  margin-left: -15px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff !important;
  font-size: 15px;
}

.table-in {
  width: 100%;
}

.table-in .el-table thead td {
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
}

.table-in .el-table tbody td {
  color: #ff5247;
  line-height: 14px;
}

.table-in .el-table td {
  text-align: center;
  border-bottom: 0;
  font-size: 12px;
  padding: 6px 0;
}

.table-in .el-table::before {
  display: none;
}

.d-flex-code {
  margin-bottom: 20px;
  white-space: nowrap;
}

.d-flex-code .txt {
  color: #ff5247;
  font-size: 12px;
  padding-right: 4px;
}

.d-flex-code .btn {
  width: 50px;
  height: 20px;
  border-radius: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #ff5247;
  font-size: 12px;
}

.d-flex-code .txt-input {
  background-color: #f4f4f4;
  text-align: center;
  width: 140px;
  height: 24px;
  line-height: 24px;
  color: #666666;
  font-size: 12px;
  margin: 0 5px;
}

.d-flex-code .text {
  color: #ff5247;
  font-size: 14px;
}
</style>
<style>
.el-dialog__body {
  color: #333333;
}

.mm-activity {
  min-width: 100%;
}

.mm-activity .house-footer {
  display: none;
}

.mm-activity .house-header {
  display: none;
}
</style>
